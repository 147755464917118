var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qualityChecklistTemplateForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            attrs: {
              submitUrl: _vm.submitUrl,
              form: _vm.form,
              submitBefore: _vm.submitBefore,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.config,
          false
        ),
        [
          _c(
            "col2-detail",
            { attrs: { span: 24 } },
            [
              _c(
                "col2-item",
                { attrs: { span: 24 } },
                [
                  _c(
                    "col2-item",
                    { staticClass: "f-wh" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "检查表模板名称",
                            prop: "name",
                            rules: [
                              {
                                required: true,
                                message: "请输入检查表模板名称",
                                trigger: ["change", "blur"],
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              maxlength: 50,
                              placeholder: "检查表模板名称",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "col2-item",
                    { staticClass: "f-wh" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "显示模式",
                            prop: "displayModel",
                            rules: [
                              {
                                required: true,
                                message: "请选择显示模式",
                                trigger: ["change", "blur"],
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.displayModelOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.displayModel,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "displayModel", $$v)
                              },
                              expression: "form.displayModel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "col2-item",
                { attrs: { span: 24 } },
                [
                  _c(
                    "col2-item",
                    { staticClass: "f-wh" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "状态",
                            prop: "status",
                            rules: [
                              {
                                required: true,
                                message: "请选择状态",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.statusOps, width: _vm.width },
                            model: {
                              value: _vm.form.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "col2-item",
                    { staticClass: "f-wh" },
                    [
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.displayModel === 1,
                              expression: "form.displayModel === 1",
                            },
                          ],
                          attrs: {
                            label: "总分数",
                            prop: "totalScore",
                            rules: [
                              {
                                required: _vm.form.displayModel === 1,
                                message: "请输入总分数",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input-number", {
                            attrs: {
                              min: 0,
                              max: 99999999,
                              placeholder: "请输入总分数",
                              maxlength: 8,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.totalScore,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "totalScore", _vm._n($$v))
                              },
                              expression: "form.totalScore",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "btn" },
                [
                  _c("v-button", {
                    staticClass: "mar-r",
                    attrs: { text: "新增" },
                    on: { click: _vm.toSelect },
                  }),
                  _c("v-button", {
                    staticClass: "mar-r",
                    attrs: { type: "danger", text: "批量删除" },
                    on: { click: _vm.batchDelete },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.displayModel === 1,
                          expression: "form.displayModel === 1",
                        },
                      ],
                      staticClass: "text",
                    },
                    [
                      _vm._v("评分权重（%）累计占比：\n            "),
                      _c("span", { class: { error: _vm.totalWeight > 100 } }, [
                        _vm._v(_vm._s(_vm.totalWeight)),
                      ]),
                      _vm._v(" %\n          "),
                    ]
                  ),
                ],
                1
              ),
              _c("table-panel-draggable", {
                ref: "tablePanel",
                staticClass: "point-table",
                class: { "show-error-style": _vm.form.displayModel === 1 },
                style: {
                  padding: "0 20px",
                  boxSizing: "border-box",
                  width: "900px",
                },
                attrs: {
                  maxHeight: _vm.maxHeight,
                  headers: _vm.tableHeaders,
                  tableData: _vm.form.detailList,
                  isMultiSelect: true,
                  hasOperateColumn: false,
                },
                on: {
                  "update:tableData": function ($event) {
                    return _vm.$set(_vm.form, "detailList", $event)
                  },
                  "update:table-data": function ($event) {
                    return _vm.$set(_vm.form, "detailList", $event)
                  },
                  handleSelectionChange: _vm.selectionChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        ref: "multiSelect",
        attrs: {
          title: "检查项列表",
          isShow: _vm.isShow,
          searchUrl: _vm.getQualityCheckItemList2URL,
          extraParams: _vm.extraParams,
          headers: _vm.orgTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.orgList,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            _vm.orgList = $event
          },
          "update:back-fill": function ($event) {
            _vm.orgList = $event
          },
          callback: _vm.onOrgSelect,
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "检查项名称", placeholder: "请输入名称" },
                  model: {
                    value: _vm.searchParams.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "name", $$v)
                    },
                    expression: "searchParams.name",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }